import { FunctionComponent, useMemo } from "react";
import { useFormContext } from "react-hook-form";


const formatter = new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" });
const Summary: FunctionComponent = () => {
  const {watch} = useFormContext();
  const parcelas = watch("payment.parcelas");
  //Valor fixo temporario para demonstração 
  const valorProduto = 47.00;
  const valorParcela = useMemo(() =>{
    return valorProduto / +parcelas; 
  }, [parcelas, valorProduto]);
  return (
    <div className="rounded-xl bg-white p-6 shadow-md border border-gray-300">
      <h2 className="text-xl font-bold mb-4 flex items-center gap-2">
        <span className="size-5 grid place-items-center rounded-full bg-action text-sm font-medium text-white">4</span>
        Resumo da compra
      </h2>
      <section className="flex flex-col ">
        <div className="grid gap-5">
          <div className="flex flex-col">
            <div className="flex gap-4 items-center">
              <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/21d6211ed16cc1b40050dc9cd330fc97d2167361964a32ab752606afa26524b8?placeholderIfAbsent=true&apiKey=403be8f9f2994c9dba75bf97d9e2431c" alt="Digital Nexus VIP" className="object-contain shrink-0 w-12 rounded aspect-square" />
              <div className="flex flex-col my-auto">
                <div className="text-base font-medium leading-tight text-zinc-400">
                  Digital Nexus | Vip
                </div>
                <div className="text-base font-bold ">
                  R$ 47,00
                </div>
              </div>
            </div>
          </div>
          <hr className="-mx-6 bg-gray-200 h-px"/>
          <div className="flex gap-5 self-start text-base">
            TOTAL
            <div className="text-right text-zinc-400">1 item</div>
            <div className="font-semibold ">{formatter.format(valorParcela*parcelas)} ({parcelas}x de {formatter.format(valorParcela)})</div>
          </div>
        </div>
      </section>
      <button className="bg-action text-white px-4 py-2 rounded-lg hover:bg-gray-800 transition font-bold w-full mt-6">
          Finalizar Compra
      </button>
    </div>
  );
}
export default Summary;
