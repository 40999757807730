import React from "react";
import Identification, { identificationSchema } from "./components/3step/Identification";
import Summary from "./components/3step/Summary";
import PaymentCard, { paymentCardSchema } from "./components/3step/PaymentCard";
import Footer from "./components/3step/Footer";
import { ReactComponent as SafePaymentIcon } from "./icons/safe-payment.svg";
import { FormProvider, useForm } from "react-hook-form";
import { object, InferType } from "yup";
import Address, { addressSchema } from "./components/3step/Address";

const appSchema = object({
  identification: identificationSchema,
  payment: paymentCardSchema,
  address: addressSchema
})

function App() {
  const form = useForm<InferType<typeof appSchema>>({
    defaultValues: {
      identification: {
        name: "",
        email: "",
        cpf: "",
        phone: "",
      },
      payment: {
        number: "",
        expiry: "",
        cvc: "",
        name: "",
        focus: undefined as "number" | "expiry" | "cvc" | "name" | undefined,
        parcelas: "1",
        cpf: "",
      },
      address: {
        cep:"",
        rua:"",
        bairro:"",
        numero:"",
        complemento:"",
        cidade:"",
        estado:"",     
      }
    }
  });
  return (
    <FormProvider {...form}>
    <div className="bg-gray-200 min-h-screen">
      <header className="py-6 bg-white px-4">
        <div className="mx-auto max-w-6xl flex items-center justify-between">
          <h1>Logo</h1>
          <SafePaymentIcon className="h-6 text-gray-500" />
        </div>
      </header>

      <main className="py-6 px-4">
        <div className="mx-auto max-w-6xl grid md:grid-cols-2 lg:grid-cols-3 gap-6 items-start">
          <div className="grid gap-6">
          <Identification />
          <Address />
          </div>
          <PaymentCard />
          <Summary />
        </div>
      </main>
      <Footer />
    </div>
    </FormProvider>
  );
}

export default App;
