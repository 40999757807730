import React from 'react';
import {ReactComponent as SafePaymentIcon} from "../../icons/safe-payment.svg";

const Footer: React.FC = () => {
  return (
    <footer className="py-10 px-4">
      <div className="mx-auto max-w-6xl flex flex-col gap-4 items-center">
        <hr className="border-gray-300 mb-6 w-full"/>
        <p className="text-sm">
          Ao clicar em "Compre agora", você concorda com os{' '}
          <button className="link text-action underline font-medium underline-offset-4" onClick={() => alert('Termos de Compra')}>Termos de Compra</button> e está ciente da{' '}
          <button className="link text-action underline font-medium underline-offset-4" onClick={() => alert('Política de Privacidade')}>Política de Privacidade</button>.
        </p>
        <SafePaymentIcon className="h-6 text-gray-500"/>
      </div>
    </footer>
  );
};

export default Footer;
