import { FunctionComponent, useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { object, string } from "yup";
import { useFormValidation } from "../utilities/forms";
import ReactInputMask from "react-input-mask";


export const identificationSchema = object({
  name: string().required().min(5),
  email: string().email().required(),
  cpf: string().required().matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/),
  phone: string().required(),
});

const Identification: FunctionComponent = () => {
  const {register,watch} = useFormContext();
  const {errors} = useFormValidation("identification", identificationSchema);

  return (
    <div className="rounded-xl bg-white p-6 shadow-md border border-gray-300">
      <h2 className="text-xl font-bold mb-4 flex items-center gap-2">
        <span className="size-5 grid place-items-center rounded-full bg-action text-sm font-medium text-white">1</span>
        <span>Dados Pessoais </span>
      </h2>
      <div className="grid gap-4">
        <p className="text-sm text-neutral-500">Solicitamos somente as informações essenciais para você fazer sua compra.</p>
        <div className="flex flex-col gap-2 grow text-base">
          <label htmlFor="fullName" className="font-medium leading-none text-black">
            Nome completo
          </label>
          <input
            {...register("identification.name")}
            id="fullName"
            type="text"
            className={`px-2 rounded border border-solid ${errors?.name ? "border-red-500" : "border-zinc-200"} h-8 outline-none`}
            placeholder="Seu nome completo"
          />
        </div>
        <div className="flex flex-col gap-2 grow text-base">
          <label htmlFor="email" className="font-medium leading-none text-black">
            E-mail
          </label>
          <input
            {...register("identification.email")}
            id="email"
            type="email"
            className={`px-2 rounded border border-solid ${errors?.email ? "border-red-500" : "border-zinc-200"} h-8 outline-none`}
            placeholder="E-mail"
          />
        </div>
        <div className="flex flex-col gap-2 grow text-base">
          <label htmlFor="CPF" className="font-medium leading-none text-black">
            CPF
          </label>
          <ReactInputMask 
            className={`px-2 rounded border border-solid ${errors?.cpf ? "border-red-500" : "border-zinc-200"} h-8 outline-none`}
            // mask options
            mask={"999.999.999-99"}
            alwaysShowMask={false}
            maskPlaceholder=''
            // input options
            type={'text'}
            placeholder="000.000.000.00"
            // react hook form register
            {...register("identification.cpf", { required: true })}
          />
        
        </div>
        <div className="flex flex-col gap-2 grow text-base">
          <label htmlFor="Celular" className="font-medium leading-none text-black">
            Celular/Whatsapp
          </label>
          <ReactInputMask 
            className={`px-2 rounded border border-solid ${errors?.phone ? "border-red-500" : "border-zinc-200"} h-8 outline-none`}
            // mask options
            mask={"(99) 99999-9999"}
            alwaysShowMask={false}
            maskPlaceholder=''
            // input options
            type={'text'}
            placeholder="Celular"
            // react hook form register
            {...register("identification.phone", { required: true })}
          />
          
        </div>
        <button className="bg-action text-white px-4 py-2 rounded-lg hover:bg-gray-800 transition font-bold disabled:bg-gray-400" disabled={!!errors}>
          Continuar
        </button>
      </div>
    </div>
  );
}
export default Identification;
