import { useMemo } from "react"
import { useFormContext, useWatch } from "react-hook-form"
import { Schema } from "yup"



export function useFormValidation(fieldName: string, schema: Schema) {
  const values = useWatch({name: fieldName});
  const {formState: {touchedFields, dirtyFields}}= useFormContext();
  const errors = useMemo(() => {
    try {
      schema.validateSync(values, {abortEarly:false})
      return null
    } catch (err) {
      return ((err as any).errors as string[]).map((error : string) => {
        const [key] = error.split(/\s+/)
        return key
      }).reduce((acc, key) => {
        if(touchedFields?.[fieldName]?.[key] || dirtyFields?.[fieldName]?.[key]){
          acc[key] = true
        }
        return acc
      }, {} as { [key: string]: boolean; })
    }
  }, [values, schema])

  return { errors }
}
