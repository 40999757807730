"use client";

import React, { useState } from "react";
import Cards from "react-credit-cards-2";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import { useFormContext } from "react-hook-form";
import { object, string } from "yup";
import {ReactComponent as CreditCardIcon} from "../../icons/credit-card.svg";
import {ReactComponent as PixIcon} from "../../icons/pix.svg";

export const paymentCardSchema = object({
  number: string(),
  expiry: string(),
  cvc: string(),
  name: string(),
  focus: string(),
  parcelas: string(),
  cpf: string(),
});

const PaymentCard = () => {
  const {register, watch} = useFormContext();
  const [focus, setFocus] = useState("");
  const payment = watch("payment");
  const [method, setMethod] = useState<'credit-card' | 'pix'>('credit-card')

  const handleInputFocus = (evt: React.FocusEvent<HTMLInputElement>) => {
    const validFocus: "number" | "expiry" | "cvc" | "name" = evt.target.name.substring(8) as
      | "number"
      | "expiry"
      | "cvc"
      | "name";
    setFocus(validFocus);
  };

  return (
    <div className="rounded-xl bg-white p-6 shadow-md border border-gray-300">
      <h2 className="text-xl font-bold mb-4 flex items-center gap-2">
        <span className="size-5 grid place-items-center rounded-full bg-action text-sm font-medium text-white">
          3
        </span>
        Pagamento
      </h2>
      <div className="grid gap-6">
        <div className={`border rounded-lg hover:shadow-md transition-all duration-300 ${method === "credit-card" ? 'shadow-md' :''}`}>
          <button className="h-14 w-full flex items-center gap-2 px-4" onClick={() => setMethod('credit-card')}>
            <CreditCardIcon className="w-10 stroke-action"/>
            <span>Cartão de Crédito</span>
          </button>
          {method === "credit-card" && (
            <div className="grid grid-cols-6 gap-4 px-4 pb-4">
              <div className="col-span-full mb-4">
                <Cards className="w-full" {...payment}
                  focused={focus}
                />
              </div>
              <div className="flex flex-col gap-2 grow text-base col-span-full">
                <label
                  htmlFor="cardNumber"
                  className="font-medium leading-none text-black"
                >
                  Número do Cartão
                </label>
                <input
                  {...register("payment.number")}
                  type="text"
                  id="cardNumber"
                  className="px-2 rounded border border-solid border-zinc-300 h-8"
                  placeholder="Digite o Número do Cartão"
                  onFocus={handleInputFocus}
                />
              </div>
              <div className="flex flex-col gap-2 grow text-base col-span-3">
                <label
                  htmlFor="titleName"
                  className="font-medium leading-none text-black"
                >
                  Titular do Cartão
                </label>
                <input
                  {...register("payment.name")}
                  type="text"
                  id="titleName"
                  className="px-2 rounded border border-solid border-zinc-300 h-8"
                  placeholder="Nome"           
                  onFocus={handleInputFocus}
                />
              </div>
              <div className="flex flex-col gap-2 grow text-base col-span-3">
                <label
                  htmlFor="titleCPF"
                  className="font-medium leading-none text-black"
                >
                  CPF do Titular
                </label>
                <input
                  {...register("payment.cpf")}
                  type="text"
                  id="titleCPF"
                  className="px-2 rounded border border-solid border-zinc-300 h-8"
                  placeholder="CPF"
                />
              </div>
              <div className="flex flex-col gap-2 grow text-base col-span-2">
                <label
                  htmlFor="month"
                  className="font-medium leading-none text-black"
                >
                  Validade
                </label>
                <input
                  {...register("payment.expiry")}
                  type="text"
                  id="month"
                  className="px-2 rounded border border-solid border-zinc-300 h-8"
                  placeholder="Mês"           
                  onFocus={handleInputFocus}
                />
              </div>
              <div className="flex flex-col gap-2 grow text-base col-span-2">
                <label htmlFor="cvv" className="font-medium leading-none text-black">
                  CVV
                </label>
                <input
                  {...register("payment.cvc")}
                  type="text"          
                  id="cvv"
                  className="px-2 rounded border border-solid border-zinc-300 h-8"
                  placeholder="CVV"           
                  onFocus={handleInputFocus}
                />
              </div>
              <div className="flex flex-col gap-2 grow text-base col-span-6">
                <label
                  htmlFor="parcelas"
                  className="font-medium leading-none text-black"
                >
                  Parcelas
                </label>
                <select
                  {...register("payment.parcelas")}
                  id="parcelas"            
                  className="px-2 rounded border border-solid border-zinc-300 h-8"
                >
                  {[...Array(10)].map((_, index) => (
                    <option key={index} value={index + 1}>
                      {index + 1}x
                    </option>
                  ))}
                </select>
              </div>
              <button className="bg-action text-white px-4 py-2 rounded-lg hover:bg-gray-800 transition col-span-6 font-bold">
                Continuar
              </button>
            </div>
          )}
        </div>
        <div className={`border rounded-lg hover:shadow-md transition-all duration-300 ${method === "credit-card" ? 'shadow-md' :''}`}>
          <button className="h-14 w-full flex items-center gap-2 px-4" onClick={() => setMethod('pix')}>
            <PixIcon className="w-10 text-action"/>
            <span>Pix</span>
          </button>
          {method === "pix" && (
            <div className="grid grid-cols-6 gap-4 px-4 pb-4">
              <p className="col-span-full text-sm font-medium">
                A confirmação de pagamento é realizada em poucos minutos. Utilize o aplicativo do seu banco para pagar.
              </p>
              <p className="col-span-full text-sm font-medium border border-black rounded-md px-3 py-2">
              IMPORTANTE: O código PIX é válido por 30 minutos após ser gerado. Faça o pagamento a tempo para garantir seus produtos.
              </p>
              <div className="flex flex-col gap-2 grow text-base col-span-6">
                <label
                  htmlFor="titleCPF"
                  className="font-medium leading-none text-black"
                >
                  CPF
                </label>
                <input
                  {...register("payment.cpf")}
                  type="text"
                  id="titleCPF"
                  className="px-2 rounded border border-solid border-zinc-300 h-8"
                  placeholder="000.000.000-00"
                />
              </div>
              <button className="bg-action text-white px-4 py-2 rounded-lg hover:bg-gray-800 transition col-span-6 font-bold">
                Continuar
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentCard;
